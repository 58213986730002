import React from 'react';

class MBI extends React.Component {
    constructor(props) {
        super(props);
        const mbiFormat = ['C', 'A', 'AN', 'N', 'A', 'AN', 'N', 'A', 'A', 'N', 'N'];

        const mbiGenerators = {
            C: Array.from({ length: 9 }, (_, i) => (i + 1).toString()),
            N: Array.from({ length: 10 }, (_, i) => i.toString()),
            A: Array.from('ABCDEFGHJKMNPQRTUVWXY'),
            AN: Array.from('ABCDEFGHJKMNPQRTUVWXY').concat(Array.from({ length: 10 }, (_, i) => i.toString())),
        };

        this.state = {
            mbi: this.generateMBI(mbiFormat, mbiGenerators),
        };

        this.mbiFormat = mbiFormat;
        this.mbiGenerators = mbiGenerators;
    }

    generateMBI = (format, generators) => {
        return format.map((c) => {
            const options = generators[c];
            return options[Math.floor(Math.random() * options.length)];
        }).join('');
    };

    regenerateMBI = () => {
        this.setState({
            mbi: this.generateMBI(this.mbiFormat, this.mbiGenerators),
        });
    };

    render() {
        return (
            <div style={{ fontFamily: 'Arial, sans-serif', textAlign: 'center', marginTop: '20px' }}>
                <h1>Generated MBI</h1>
                <p style={{ fontSize: '24px', fontWeight: 'bold', margin: '10px 0' }}>{this.state.mbi}</p>
                <button 
                    onClick={this.regenerateMBI} 
                    style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer', borderRadius: '5px', border: '1px solid #ccc' }}
                >
                    Generate New MBI
                </button>
            </div>
        );
    }
}

export default MBI;
